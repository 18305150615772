import {
  StyleParamType,
  createStylesParams,
  wixFontParam,
  wixColorParam,
} from '@wix/tpa-settings';

import { IChallengeStyles } from './Settings/challengeStyles/challengeStyles.types';
import { challengeStyles as mobileStyles } from './Settings.mobile/challengeStyles';
import { fontValueFix } from '../ChallengesList/stylesParams';

export default createStylesParams<IChallengeStyles>({
  /**
   * sidebar styles
   */
  sidebarLayoutProgressBarProgressColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarLayoutSelectedStepColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  sidebarLayoutOverviewDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutOverviewDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutOverviewTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 28,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutOverviewTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutBodyDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutBodyDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutBodyStepNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: isMobile ? 16 : 28,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutBodyStepNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutSidebarStepNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutSidebarStepNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutSidebarSectionNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutSidebarSectionNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutSidebarChallengeNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 20,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutSidebarChallengeNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutStepDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarLayoutDividerOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  sidebarLayoutBodyBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutSidebarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutButtonBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarLayoutButtonBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarLayoutButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarLayoutButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sidebarLayoutButtonHoverBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  sidebarLayoutButtonHoverBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  sidebarLayoutButtonHoverFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutBodySidePaddingFullWidth: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 80;
    },
  },
  sidebarLayoutBodySidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 32;
    },
  },
  sidebarLayoutSidebarBodySpaceFromTop: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 32;
    },
  },
  sidebarLayoutSidebarSpaceBetweenSteps: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 20;
    },
  },
  /**
   * sidebar styles
   */
  sidebarLayoutSidebarSidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 20;
    },
  },
  sidebarLayoutSpace: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0;
    },
  },
  sidebarLayoutProportionsFullWidth: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 30;
    },
  },
  sidebarLayoutProportions: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 30;
    },
  },
  /**
   * list layout participant page styles
   */
  listLayoutButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  listLayoutButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  listLayoutButtonBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listLayoutButtonBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listLayoutButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  listLayoutButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  listLayoutButtonHoverBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  listLayoutButtonHoverBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  listLayoutButtonHoverFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  listLayoutStepDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutStepDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  listLayoutSectionNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutSectionNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  listLayoutStepNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutStepNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  listLayoutOverviewDescriptionTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutOverviewDescriptionTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  listLayoutOverviewTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutOverviewTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 28,
        }),
        textPresets,
      );
    },
  },
  listLayoutStepDateColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutStepDateFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 28,
        }),
        textPresets,
      );
    },
  },
  listLayoutHeaderMenuColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutHeaderMenuFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  listLayoutHeaderInfoColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutHeaderInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  listLayoutChallengeNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listLayoutChallengeNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: isMobile ? 20 : 48,
        }),
        textPresets,
      );
    },
  },
  listLayoutStepDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  listLayoutStepDividerOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  listLayoutHeaderBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  listLayoutPageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  listLayoutActiveTabColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  /**
   * visitor styles
   */
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  pageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  challengeNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 48,
        }),
        textPresets,
      );
    },
  },
  challengeNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dateFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  dateColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  priceFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  priceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 28,
        }),
        textPresets,
      );
    },
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  countersFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 24,
        }),
        textPresets,
      );
    },
  },
  countersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  countersDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  countersDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 20,
        }),
        textPresets,
      );
    },
  },
  buttonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  buttonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonHoverBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  dividerLength: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  linksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  agendaBoxPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  agendaBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  agendaBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  agendaDividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaDividersWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  agendaIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaSectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  agendaSectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaStepNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  agendaStepNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaStepCountFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 14,
        }),
        textPresets,
      );
    },
  },
  agendaStepCountColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  agendaLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  videoOverlayColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.1),
  },
  videoPlayButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.7),
  },
  ...mobileStyles,
});
